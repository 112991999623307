import http from '@/utils/http'

// // post请求
// export const demo1 = (params) => {
//     return http.request({
//         url: '/v1/demo',
//         method: 'post',
//         data: params
//     })
// }

// // get请求
// export const demo2 = (params) => {
//     return http.request({
//         url: '/v1/demo',
//         method: 'get',
//         params: params
//     })
// }

// 获取比赛列表
export const getMatch = (params) => {
    return http.request({
        url: '/v1/ballsports/base/match/list',
        method: 'get',
        params: params
    })
}

// 获取比赛关联关系
export const getMatchRelation = (params) => {
    return http.request({
        url: "/v1/ballsports/base/match/relation",
        method: 'get',
        params: params
    })
}


// 保存关联的源数据
export const updateMatchSources = (params) => {
    return http.request({
        url: "/v1/ballsports/base/match/relation_update",
        method: 'post',
        data: params,
    })
}

// // 获取比赛详情数据  // 用于编辑和
// export const getMatchDetail = (params) => {
//     return http.request({
//         url: "/v1/ballsports/base/match/detail",
//         method: 'get',
//         params: params
//     })
// }

// 获取比赛详情比分 
export const getMatchScore = (params) => {
    return http.request({
        url: "/v1/ballsports/base/match/score",
        method: 'get',
        params: params
    })
}

export const updateMatchScore = (params) => {
    return http.request({
        url: "/v1/ballsports/base/match/score_update",
        method: 'post',
        data: params,
    })
}

// 获取统计详情
export const getMatchStat = (params) => {
    return http.request({
        url: "/v1/ballsports/base/match/stat",
        method: 'get',
        params: params
    })
}

export const updateMatchStat = (params) => {
    return http.request({
        url: "/v1/ballsports/base/match/stat_update",
        method: 'post',
        data: params,
    })
}

// 获取事件
export const getMatchEvent = (params) => {
    return http.request({
        url: "/v1/ballsports/base/match/event",
        method: 'get',
        params: params
    })
}

// 阵容
export const getMatchLineup = (params) => {
    return http.request({
        url: "/v1/ballsports/base/match/lineup",
        method: 'get',
        params: params
    })
}

export const updateMatchLock = (params) => {
    return http.request({
        url: "/v1/ballsports/base/match/lock/update",
        method: 'post',
        data: params,
    })
}

// 更新比赛
export const updateMatch = (params) => {
    return http.request({
        url: "/v1/ballsports/base/match/update",
        method: 'post',
        data: params,
    })
}
// 搜索比赛
export const searchMatch = (params) => {
    return http.request({
        url: "/v1/ballsports/base/match/search",
        method: 'post',
        data: params,
    })
}