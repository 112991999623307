<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
            <Form label-position="left" :label-width="80" >
                <FormItem label="id" v-if="data.id">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.id" disabled></Input>
                        </Col>
                    </Row>
                </FormItem>
                
                <FormItem v-for="item in langs" :key="item.key" :index="item.key" :label="item.title">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data['name_'+item.key]"  ></Input>
                        </Col>
                    </Row>
                </FormItem>

                <!-- <FormItem label="是否删除: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.deleted" >
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem> -->
            </Form>
            </TabPane>
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import { getTeamTrans, updateTeamTrans } from '@/api/ballsports/trans/team';

export default {
    name: "team-trans-edit",
    props: {
        sport_id: Number,   // 运动id
        team_id: Number,   // 联赛id
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            spinShow: false,
            data: {
                id: 0,
            },
            langs: {},
        }
    },
    methods: {
        getData () {
            let self = this;
            if (self.team_id && self.team_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.team_id,
                }
                getTeamTrans( params ).then(response => {
                    if (response.data.code == 0) {
                        self.data = response.data.data.row;
                        self.langs = response.data.data.langs ;
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        save() {
            let self = this;
            let subs = []
            if (self.data.subs && self.data.subs.length > 0){
                self.data.subs.forEach(element => {
                    subs.push(element.id)
                });
            }
            let params = {
                id: self.data.id,
                sport_id: self.data.sport_id || self.sport_id,
            }
            for (var item in self.langs){
                params[ "name_"+item ] = self.data["name_" + item];
            }
            // console.log(params)
            updateTeamTrans(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
            
        },
        // end
    },
    mounted () {
        // let self = this;
        // self.getData();
    },
    watch: {
        "team_id":{
            handler(value){
                let self = this;
                if (value >= 0){
                    self.getData();
                };
            },
	        immediate: true,  // 深度触发
        }
    },
    computed: {
    }
}
</script>