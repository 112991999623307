import http from '@/utils/http';

// 获取
export const getTeamTrans = (params) => {
    return http.request({
        url: '/v1/ballsports/base/team/trans/get',
        method: 'get',
        params: params
    })
}

// 更新
export const updateTeamTrans = (params) => {
    return http.request({
        url: "/v1/ballsports/base/team/trans/update",
        method: 'post',
        data: params,
    })
}

export const getTeamShortTrans = (params) => {
    return http.request({
        url: '/v1/ballsports/base/team/trans_short/get',
        method: 'get',
        params: params
    })
}

// 更新
export const updateTeamShortTrans = (params) => {
    return http.request({
        url: "/v1/ballsports/base/team/trans_short/update",
        method: 'post',
        data: params,
    })
}
