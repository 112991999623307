<template>
    <div>
        <Modal
            v-model="modal_add_sub"
            title="添加多打战队"
            @on-ok="confirmAddSubTeam"
            >
            <teamSearch v-model="new_sub" :sport_id="sport_id" ></teamSearch>
        </Modal>
        
        <Drawer
            v-model="drawer_trans"
            width="450"
            :mask-closable="true"
            :transfer="true"
        >
            <teamTransEdit :sport_id="sport_id" :team_id="trans_id" @closePage="closeTransPage"></teamTransEdit>
        </Drawer> 

        <Drawer
            v-model="drawer_trans_short"
            width="450"
            :mask-closable="true"
            :transfer="true"
        >
            <teamShortTransEdit :sport_id="sport_id" :team_id="trans_id" @closePage="closeTransPage"></teamShortTransEdit>
        </Drawer> 

        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
            <Form label-position="left" :label-width="80" >
                <FormItem label="id: " v-if="data.id">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.id" disabled></Input>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="双打(多打): ">
                    <Row >
                        <Col span="20" style="text-align:left;" >
                            <Row :class="locks[String(type)+'-1-'+'sub_ids'] === 1? 'locked-row': '' ">
                                <Col span="8" v-for="(item, _index) in (data.subs || [])" :key="item.id">
                                    <Row class="demo-upload-list" >
                                        <img :src="item.logo? $conf.ballsports.img_prefix + '/v2/team/' + item.logo: '' " />
                                        <div class="demo-upload-list-cover" >
                                            <Icon type="ios-trash-outline" @click.native="handleRemoveSub(_index)"></Icon>
                                        </div>
                                    </Row>
                                    <Row >
                                        <span class="text-link" @click="jumpToUser(item.id)">{{item.id}}</span>
                                    </Row>
                                    <Row>
                                        <span class="text-assist" >{{item.name}}</span>
                                    </Row>
                                </Col>
                                <Col class="demo-upload-list">
                                    <!-- <div class="demo-upload-list-cover">
                                        <Icon type="md-add" @click="modal_add_sub = true"></Icon>
                                    </div> -->
                                    <Button shape="circle" icon="md-add" @click="modal_add_sub = true" ></Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'sub_ids']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="1" _key="sub_ids"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="名称: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name" :class="locks[String(type)+'-1-'+'name'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'name']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="1" _key="name"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="简称: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.abbr" :class="locks[String(type)+'-1-'+'abbr'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'abbr']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="1" _key="abbr"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="logo: ">
                    <Row>
                        <imgEditor v-model="data.logo" :prefix="$conf.ballsports.img_prefix" :dir="sport_id===6? 'baseball/team': (sport_id===10 ? 'volleyball/team': 'v2/team')" 
                            :previewButton='true' :clearButton='true' cdn='ballsports'>
                        </imgEditor>
                        <!-- <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'deleted']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="1" _key="deleted"></Lock>
                        </Col> -->
                    </Row>
                </FormItem>
                <FormItem label="国家: ">
                    <Row>
                        <Col span="20" >
                            <countrySearch :sport_id="sport_id" v-model="data.country" :class="locks[String(type)+'-1-'+'country_id'] === 1? 'locked-input': '' " ></countrySearch>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'country_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="1" _key="country_id"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="性别: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.gender" :class="locks[String(type) + '-1-' + 'gender'] === 1? 'locked-select': '' ">
                                <Option :value="0" :key="0">未知</Option>
                                <Option :value="1" :key="1">男</Option>
                                <Option :value="2" :key="2">女</Option>
                            </Select>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'gender']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="1" _key="gender"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="国家队: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.national" :class="locks[String(type) + '-1-' + 'national'] === 1? 'locked-select': '' ">
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'national']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="1" _key="national"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="中文简体: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name_zh" :class="locks[String(type)+'-1-'+'name_zh'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'name_zh']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="1" _key="name_zh"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="中文繁体: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name_zht" :class="locks[String(type)+'-1-'+'name_zht'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'name_zht']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="1" _key="name_zht"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="英文: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name_en" :class="locks[String(type)+'-1-'+'name_en'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'name_en']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="1" _key="name_en"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="是否删除: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.deleted" :class="locks[String(type) + '-1-' + 'deleted'] === 1? 'locked-select': '' ">
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'deleted']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="1" _key="deleted"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="全称翻译: ">
                    <Row>
                        <Col span="20" >
                            <Button type="default" long @click="editTrans">翻译</Button>
                        </Col>
                    </Row>
                </FormItem>
                
                <FormItem label="简称翻译: ">
                    <Row>
                        <Col span="20" >
                            <Button type="default" long @click="editShortTrans">翻译</Button>
                        </Col>
                    </Row>
                </FormItem>

                <!-- <FormItem label="分类: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.category_id" :class="locks[String(type) + '-1-' + 'category_id'] === 1? 'locked-select': '' ">
                                <Option v-for="item in category_dic" :value="item.id" :key="item.id">{{ item.name }}</Option>
                            </Select>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-' + 'category_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="1" _key="category_id"></Lock>
                        </Col>
                    </Row>
                </FormItem> -->
            </Form>
            </TabPane>
            <TabPane label="额外信息" name="2">
                <Form label-position="left" :label-width="80">
                    <FormItem label="身高(cm): ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.extra.height" :class="locks[String(type)+'-2-'+'height'] === 1? 'locked-input': '' " number></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'height']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="2" _key="height"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="体重(kg): ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.extra.weight" :class="locks[String(type)+'-2-'+'weight'] === 1? 'locked-input': '' " number></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'weight']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="2" _key="weight"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="生日: " >
                        <Row>
                            <Col span="20" >
                                <DatePicker 
                                    type="datetime" 
                                    format="yyyy-MM-dd" 
                                    :class="locks[String(type) + '-2-' + 'birthday'] === 1? 'locked-input': '' "
                                    style="width: 100%"
                                    v-model="birthday"
                                >
                                </DatePicker>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'birthday']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="2" _key="birthday"></Lock>
                            </Col>
                        </Row>
                    </FormItem> 
                    <FormItem label="惯用手: ">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.extra.plays_id" :class="locks[String(type) + '-2-' + 'plays_id'] === 1? 'locked-select': '' ">
                                    <Option :value="0" :key="0">未知</Option>
                                    <Option :value="1" :key="1">左手</Option>
                                    <Option :value="2" :key="2">右手</Option>
                                </Select>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'plays_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="2" _key="plays_id"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="教练名称: ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.extra.coach_name" :class="locks[String(type)+'-2-'+'coach_name'] === 1? 'locked-input': '' "></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'coach_name']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="2" _key="coach_name"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="擅长场地: ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.extra.favourite_surface" :class="locks[String(type)+'-2-'+'favourite_surface'] === 1? 'locked-input': '' "></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'favourite_surface']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="2" _key="favourite_surface"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="转职业年份: ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.extra.turned_pro" :class="locks[String(type)+'-2-'+'turned_pro'] === 1? 'locked-input': '' "></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'turned_pro']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="2" _key="turned_pro"></Lock>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="居住地: ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.extra.residence" :class="locks[String(type)+'-2-'+'residence'] === 1? 'locked-input': '' " ></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'residence']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="2" _key="residence"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="出生地: ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.extra.birthplace" :class="locks[String(type)+'-2-'+'birthplace'] === 1? 'locked-input': '' " ></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'birthplace']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="2" _key="birthplace"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="总奖金: ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.extra.prize_total" :class="locks[String(type)+'-2-'+'prize_total'] === 1? 'locked-input': '' " number></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'prize_total']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="2" _key="prize_total"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="当前奖金: ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.extra.prize_current" :class="locks[String(type)+'-2-'+'prize_current'] === 1? 'locked-input': '' " number></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'prize_current']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="2" _key="prize_current"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    
                    <FormItem label="最高排名: ">
                        <Row>
                            <Col span="20" >
                                <Row >
                                    <Col span="4">
                                        单打: 
                                    </Col>
                                    <Col span="10">
                                        <DatePicker 
                                            type="month" 
                                            format="MM.yyyy" 
                                            style="width: 100%"
                                            :class="locks[String(type)+'-2-'+'highest_ranking'] === 1? 'locked-input': '' "
                                            v-model="highest_ranking_singles_date"
                                            placeholder="日期"
                                        >
                                        </DatePicker>
                                    </Col>
                                    <Col span="10">
                                        <Input placeholder="名次" v-model="highest_ranking_singles_ranking" :class="locks[String(type)+'-2-'+'highest_ranking'] === 1? 'locked-input': '' " number></Input>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col span="4">
                                        双打: 
                                    </Col>
                                    <Col span="10">
                                        <DatePicker 
                                            type="month" 
                                            format="MM.yyyy" 
                                            style="width: 100%"
                                            :class="locks[String(type)+'-2-'+'highest_ranking'] === 1? 'locked-input': '' "
                                            v-model="highest_ranking_doubles_date"
                                            placeholder="日期"
                                        >
                                        </DatePicker>
                                    </Col>
                                    <Col span="10">
                                        <Input placeholder="名次" v-model="highest_ranking_doubles_ranking" :class="locks[String(type)+'-2-'+'highest_ranking'] === 1? 'locked-input': '' " number></Input>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'highest_ranking']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_id" :type="type" :sub_type="2" _key="highest_ranking"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    
                </Form>
            </TabPane>
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import countrySearch from "../country/search.vue";
import teamSearch from "./search.vue";
import Lock from '../lock.vue';
import { getTeam, updateTeam } from '@/api/ballsports/base/team';
import moment from 'moment';
import imgEditor from '@/components/img-editor/img-editor.vue';
import teamTransEdit from './trans/edit.vue';
import teamShortTransEdit from './trans_short/edit.vue';

export default {
    name: "team-edit",
    props: {
        sport_id: Number,   // 运动id
        team_id: Number,   // 联赛id
    },
    components: {
        Lock,
        countrySearch,
        teamSearch,
        imgEditor,
        teamTransEdit,
        teamShortTransEdit,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 5,  // 战队
            type: 1,     // 基本数据
            spinShow: false,
            data: {
                id: 0,
                sport_id: self.sport_id,
                name:'',
                abbr:'',
                deleted: 0,
                extra: {},
                country: {},
            },
            locks: {},
            modal_add_sub: false,
            new_sub: {
                "id": 0,
                "name": '',
                "logo": '',
            },
            drawer_trans: false,
            trans_id: 0,
            drawer_trans_short: false,
        }
    },
    methods: {
        // 获取联赛分类
        // getCategorys(){
        //     var params = {
        //         sport_id: this.sport_id,
        //     }
        //     getTeamCategory(params).then(response => {
        //         if (response.data.code == 0) {
        //             this.category_dic = response.data.data;
        //         } else {
        //             this.$Message.error(response.data.msg);
        //         }
        //     })
        // },
        getData () {
            let self = this;
            if (self.team_id && self.team_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.team_id,
                }
                getTeam( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            self.locks = response.data.data.locks ;
                        }else{
                            this.data = {}
                            self.locks = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                // alert(1)
                // self.data = {
                //     id: 0,
                //     sport_id: self.sport_id,
                //     name:'',
                //     abbr:'',
                //     deleted: 0,
                //     extra: {},
                //     country: {},
                // }
                self.locks = {}
            }
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        save() {
            let self = this;
            let subs = []
            if (self.data.subs && self.data.subs.length > 0){
                self.data.subs.forEach(element => {
                    subs.push(element.id)
                });
            }
            let params = {
                id: self.data.id,
                sport_id: self.data.sport_id || self.sport_id,
                sub_ids: subs,
                country_id: self.data.country.id,
                name: self.data.name,
                abbr: self.data.abbr,
                logo: self.data.logo,
                national: self.data.national,
                gender: self.data.gender,
                extra: self.data.extra,
                deleted: self.data.deleted,
                // 更新翻译表的数据
                name_zh : self.data.name_zh,
                name_zht : self.data.name_zht,
                name_en : self.data.name_en,
            }
            updateTeam(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                    // 更新翻译表
                    // let trans_params = {
                    //     id: self.data.id,
                    //     sport_id: self.data.sport_id || self.sport_id,
                    //     name_zh: self.data.name_zh,
                    //     name_zht: self.data.name_zht,
                    //     name_en: self.data.name_en,
                    // }
                    // console.log(trans_params);
                    // updateTeamTran(params).then(response => {
                    //     if (response.data.code == 0) {
                    //         this.$Message.success("保存成功")
                    //         self.emit("save")
                    //     } else {
                    //         this.$Message.error(response.data.msg)
                    //     }
                    // })
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
            
        },
        jumpToUser(team_id){
            // alert(uid);
            let query = { sport_id: this.sport_id, team_id: team_id }
            // let resolve_data = this.$router.resolve({ path: '/ballsports/base/team/detail', query: query })
            // window.open(resolve_data.href, '_blank')
            let sport = this.$conf.ballsports.sports[this.sport_id];
            if (sport){
                let path = '/ballsports/'+ sport.name_en +'/team/detail';
                let resolve_data = this.$router.resolve({ path: path, query: query });
                window.open(resolve_data.href, '_blank')
            }else{
                this.$Message.error("暂不支持的运动类型");
            }
        },
        // end
        confirmAddSubTeam(){
            // 添加子球队
            let self = this;
            if (! self.data.subs){
                self.data.subs = [];
            }
            self.data.subs.push(self.new_sub);
        },
        // end 
        handleRemoveSub(_index){
            // 根据索引移除子队伍
            let self = this;
            self.data.subs.splice(_index, 1);
        },
        // end
        // 检查是否为有效时间
        isValidDate(date) {
            return date instanceof Date && !isNaN(date.getTime())
        },
        // end
        editTrans(){
            let self = this;
            self.drawer_trans_short = false;
            self.drawer_trans = true;
            self.trans_id = self.team_id;
        },
        editShortTrans(){
            let self = this;
            self.drawer_trans = false;
            self.drawer_trans_short = true;
            self.trans_id = self.team_id;
        },
        // end 
        closeTransPage(){
            let self = this;
            self.drawer_trans = false;
            self.drawer_trans_short = false;
            self.trans_id = 0;
        },
        // end

    },
    mounted () {
        // let self = this;
        // self.getData();
    },
    watch: {
        "team_id":{
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    // self.getCategorys();
                    self.getData();
                };
            },
	        immediate: true,  // 深度触发
        }
    },
    computed: {
        highest_ranking_singles_date: {
            get: function () {
                if (this.data.extra){
                    if (this.data.extra.highest_ranking){
                        if (this.data.extra.highest_ranking.singles){
                            if (this.data.extra.highest_ranking.singles.date){
                                return this.data.extra.highest_ranking.singles.date
                            }
                        }
                    }
                }
                return '';
            },
            set: function (newValue) {
                if (!this.data.extra ){
                    this.data.extra = {};
                }
                if (!this.data.extra.highest_ranking){
                    this.data.extra.highest_ranking = {}
                }
                if (!this.data.extra.highest_ranking.singles){
                    this.data.extra.highest_ranking.singles = {}
                }
                if ( this.isValidDate (newValue) ){
                    this.data.extra.highest_ranking.singles.date = moment(newValue).format("MM.YYYY");
                }else{
                    this.data.extra.highest_ranking.singles.date = '';
                }
            }
        },
        highest_ranking_singles_ranking: {
            get: function () {
                if (this.data.extra){
                    if (this.data.extra.highest_ranking){
                        if (this.data.extra.highest_ranking.singles){
                            if (this.data.extra.highest_ranking.singles.ranking){
                                return this.data.extra.highest_ranking.singles.ranking
                            }
                        }
                    }
                }
                return '';
            },
            set: function (newValue) {
                if (!this.data.extra ){
                    this.data.extra = {};
                }
                if (!this.data.extra.highest_ranking){
                    this.data.extra.highest_ranking = {}
                }
                if (!this.data.extra.highest_ranking.singles){
                    this.data.extra.highest_ranking.singles = {}
                }
                this.data.extra.highest_ranking.singles.ranking = newValue;
            }
        },
        highest_ranking_doubles_date: {
            get: function () {
                if (this.data.extra){
                    if (this.data.extra.highest_ranking){
                        if (this.data.extra.highest_ranking.doubles){
                            if (this.data.extra.highest_ranking.doubles.date){
                                return this.data.extra.highest_ranking.doubles.date
                            }
                        }
                    }
                }
                return '';
            },
            set: function (newValue) {
                if (!this.data.extra ){
                    this.data.extra = {};
                }
                if (!this.data.extra.highest_ranking){
                    this.data.extra.highest_ranking = {}
                }
                if (!this.data.extra.highest_ranking.doubles){
                    this.data.extra.highest_ranking.doubles = {}
                }
                if ( this.isValidDate (newValue) ){
                    this.data.extra.highest_ranking.doubles.date = moment(newValue).format("MM.YYYY");
                }else{
                    this.data.extra.highest_ranking.doubles.date = '';
                }
            }
        },
        highest_ranking_doubles_ranking: {
            get: function () {
                if (this.data.extra){
                    if (this.data.extra.highest_ranking){
                        if (this.data.extra.highest_ranking.doubles){
                            if (this.data.extra.highest_ranking.doubles.ranking){
                                return this.data.extra.highest_ranking.doubles.ranking
                            }
                        }
                    }
                }
                return '';
            },
            set: function (newValue) {
                if (!this.data.extra ){
                    this.data.extra = {};
                }
                if (!this.data.extra.highest_ranking){
                    this.data.extra.highest_ranking = {}
                }
                if (!this.data.extra.highest_ranking.doubles){
                    this.data.extra.highest_ranking.doubles = {}
                }
                this.data.extra.highest_ranking.doubles.ranking = newValue;
            }
        },
        birthday: {
            get: function () {
                return new Date(this.data.extra.birthday);
            },
            set: function (newValue) {
                if ( this.isValidDate (newValue) ){
                    this.data.extra.birthday = moment(newValue).format("YYYY-MM-DD");
                }else{
                    this.data.extra.birthday  = '';
                }
                
            }
        },
        // qualification: {
        //     get: function () {
        //         return this.data.extra.qualification? 1: 0;
        //     },
        //     set: function (newValue) {
        //         this.data.extra.qualification = newValue===1?true: false;
        //     }
        // },
    }
}
</script>

<style scoped>
    @import "detail.css"
</style>