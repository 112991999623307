<template>
    <span v-if="obj_id && obj_id>0">
        <Icon type="md-lock" v-if="status === 1" @click.native="unlock()" />
        <Icon type="md-unlock" v-else @click.native="lock()" />
    </span>
</template>

<script>

import { updateMatchLock } from '@/api/ballsports/base/match';

export default {
    name: "Lock",
    props: {
        sport_id: Number,   // 运动id
        obj_type: Number,   // 类型 1比赛 2联赛 
        obj_id  : Number,   // 比赛id / 联赛id / 赛季id 等
        type    : Number,   // 类型 1比分 2统计 3事件 4阵容
        sub_type: Number,   // 每个类型下的子类型
        _key    : String,   // 唯一标识 如果类型与子类型可以定义清楚 则不需要当前字段
        value   : Number,    // 0不上锁 1上锁
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            status: this.value, // 锁的状态
        }
    },
    methods: {
        // 
        lock(){
            this.status = 1;
            this._emit();
            this.api_update();
        },
        // end 
        unlock(){
            this.status = 0;
            this._emit();
            this.api_update();
        },
        // end 
        _emit(){
            this.$emit('input', this.status);
        },
        // end 
        api_update(){
            let self = this;
            var params = {
                sport_id: this.sport_id,
                obj_type: this.obj_type,
                obj_id  : this.obj_id,
                type    : this.type,
                sub_type: this.sub_type,
                _key    : this._key,
                value   : this.status,
            }
            // 请求接口更新到数据库
            updateMatchLock(params).then(response => {
                if (response.data.code == 0) {
                    // do nothing
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
        // end 
    },
    mounted () {
    },
    watch: {
        value(status){
            this.status = status;
        }
    },
    computed: {
    }
}
</script>
<style scoped>
    /* @import "detail.css" */
</style>